<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div
      @mousedown.stop
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Metric history</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body p-0">
          <div v-if="error || isLoading" class="p-3">
            <ErrorMessage v-if="error" :error="error"></ErrorMessage>
            <LoadingMessage v-if="isLoading"></LoadingMessage>
          </div>

          <div v-if="!isLoading">
            <div v-if="userMetric">
              <!-- METRIC INFO -->
              <div class="d-flex align-items-center p-3 border-bottom header">
                <div class="flex-grow-1">
                  <div class="h3 fw-normal m-0 mb-1">{{ userMetric.name }}</div>
                  <div class="small">
                    Target: {{ userMetric | metricTargetReadable }} &mdash;
                    <a
                      @click="showNewTargetModal = true"
                      v-if="authUserIsCoach"
                      class="fst-italic"
                      role="button"
                    >Update</a>
                  </div>
                </div>
                <div class="ms-3">
                  <button
                    @click="showNewEntryModal = true"
                    class="btn btn-primary"
                  >Add entry</button>
                </div>
              </div>

              <!-- RECORDS -->
              <div v-if="records.length" class="p-3">
                <!-- CHART VIEW -->
                <LineChart
                  :chartData="chartDataFromRecords(records)"
                  :unit="userMetric.unit"
                  class="chart"
                ></LineChart>

                <!-- TABLE VIEW -->
                <div class="list-group mt-3">
                  <MetricHistoryItem
                    @delete="deleteRecord"
                    v-for="(r, rIndex) in records"
                    :key="`key-${rIndex}`"
                    :item="r"
                    :unit="userMetric.unit"
                    class="list-group-item"
                  ></MetricHistoryItem>
                </div>
              </div>
              <div v-else class="p-3">No records found</div>
            </div>
            <div v-else class="p-3">Metric not found</div>
          </div>

          <portal to="modal">
            <transition name="modal">
              <MetricNewTargetModal
                @close="showNewTargetModal = false"
                @update="updateTarget"
                v-model="showNewTargetModal"
                v-if="showNewTargetModal"
                :metric="userMetric"
                :metricId="userMetric.metricId"
                :userId="userId"
              ></MetricNewTargetModal>
            </transition>
          </portal>

          <portal to="modal">
            <transition name="modal">
              <MetricNewEntryModal
                @close="showNewEntryModal = false"
                @update="fetchData"
                v-model="showNewEntryModal"
                v-if="showNewEntryModal"
                :metric="userMetric"
                :metricId="userMetric.metricId"
                :userId="userId"
              ></MetricNewEntryModal>
            </transition>
          </portal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';
import { deepCopy } from '@/core/util';

export default {
  name: 'MetricHistoryModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    LineChart: () => import('@/components/chart/LineChart'),
    MetricHistoryItem: () => import('@/components/MetricHistoryItem'),
    MetricNewTargetModal: () => import('@/components/modal/MetricNewTargetModal'),
    MetricNewEntryModal: () => import('@/components/modal/MetricNewEntryModal'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    metricId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async fetchData() {
      this.isLoading = true;
      try {
        const { metricId, userId } = this;
        const resMetric = await httpGet(`/metric/${metricId}/user/${userId}`);
        const resHistory = await httpGet(`/metric/${metricId}/user/${userId}/history`);
        this.userMetric = resMetric.data;
        this.records = resHistory.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    chartDataFromRecords(records) {
      const chartData = {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      };

      const copy = deepCopy(records);
      copy.reverse().forEach((item) => {
        const date = this.$options.filters.dateReadableShort(item.createdAt);
        chartData.labels.push(date);
        chartData.datasets[0].data.push(item.value);
      });

      return chartData;
    },
    deleteRecord(id) {
      const { records } = this;
      if (records && records.length) {
        const index = records.findIndex((r) => r.metricHistoryId === id);
        if (index >= 0) records.splice(index, 1);
      }
    },
    updateTarget(newValue) {
      this.userMetric.target = newValue;
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      userMetric: null,
      records: null,
      showNewTargetModal: false,
      showNewEntryModal: false,
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

.header {
  background-color: $tertiarySystemBackground;
}

.chart {
  max-height: 20rem;
}
</style>
